export default function useSnowplow() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const { $optIn, $optOut, $optOutActive } = useNuxtApp();

  /********************
   * REFS & VARS       *
   ********************/
  const snowplowOptOutLinktText = ref<string | null>(null);
  const snowplowOptOutSuccessText = ref<string | null>(null);
  const snowplowOptOutReactivateText = ref<string | null>(null);

  /********************
   * FUNCTIONS         *
   ********************/
  function rewriteHTMLForSnowplowOptOut(html: string) {
    const search = /<a href="#snowplow-opt-out">([^<^|]+)\|([^<^|]+)\|([^<^|]+)<\/a>/;
    const match = html.match(search);
    if (!match) {
      return html;
    }
    snowplowOptOutLinktText.value = match[1];
    snowplowOptOutSuccessText.value = match[2];
    snowplowOptOutReactivateText.value = match[3];
    return html.replace(search, '<span class="snowplow-opt-out"></span>');
  }

  function emptySnowPlowOptOutLinkWrapper(span: Element) {
    span.innerHTML = '';
  }

  function addSnowPlowOptOutLink(span: Element) {
    const optOutLink: HTMLAnchorElement = document.createElement('a');
    optOutLink.innerHTML = snowplowOptOutLinktText.value || '';
    optOutLink.href = '#snowplow-opt-out';
    optOutLink.addEventListener('click', snowplowOptOutLinkClicked);
    span.appendChild(optOutLink);
  }

  function initializeSnowplowOptOut(ele: Element | null) {
    if (!ele) {
      return;
    }
    const links: HTMLCollectionOf<Element> = ele.getElementsByClassName('snowplow-opt-out');

    if (!links.length) {
      return;
    }

    for (const link of links) {
      emptySnowPlowOptOutLinkWrapper(link);
      if (!$optOutActive()) {
        // if (!this.$snowplow.optOutActive()) {
        addSnowPlowOptOutLink(link);
      } else {
        setSnowplowOptoutSuccess(link);
      }
    }
  }

  function snowplowOptOutLinkClicked(event: MouseEvent) {
    event.preventDefault();
    $optOut();
    if (event.currentTarget?.parentNode) {
      setSnowplowOptoutSuccess(event.currentTarget.parentNode);
    }
    return false;
  }

  function snowplowOptInLinkClicked(event: MouseEvent) {
    event.preventDefault();
    $optIn();
    if (event.currentTarget?.parentNode) {
      const span = event.currentTarget.parentNode;
      emptySnowPlowOptOutLinkWrapper(span);
      addSnowPlowOptOutLink(span);
    }
    return false;
  }

  function setSnowplowOptoutSuccess(span: Element) {
    emptySnowPlowOptOutLinkWrapper(span);

    const successEle: HTMLElement = document.createElement('span');
    successEle.innerHTML = snowplowOptOutSuccessText.value || '';
    const classes = 'ml-1 inline-block rounded bg-primary px-[7px] py-[3px] text-xs font-medium leading-3 text-white';
    classes.split(' ').forEach((cls: string) => {
      successEle.classList.add(cls);
    });

    const reactivateEle: HTMLAnchorElement = document.createElement('a');
    reactivateEle.innerHTML = snowplowOptOutReactivateText.value || '';
    reactivateEle.href = '#';
    reactivateEle.addEventListener('click', snowplowOptInLinkClicked);

    const nbsp = document.createTextNode('\u00A0');

    span.appendChild(successEle);
    span.appendChild(nbsp);
    span.appendChild(reactivateEle);
  }

  return {
    addSnowPlowOptOutLink,
    emptySnowPlowOptOutLinkWrapper,
    initializeSnowplowOptOut,
    rewriteHTMLForSnowplowOptOut,
    setSnowplowOptoutSuccess,
    snowplowOptInLinkClicked,
    snowplowOptOutLinkClicked,
  };
}
