<script setup lang="ts">
/********************
 * PROPS & EMITS    *
 ********************/
export interface CiRichTextProps {
  extra?: any;
  fields: any;
  language?: string | undefined;
}
const props = withDefaults(defineProps<CiRichTextProps>(), {
  extra: {},
  language: undefined,
});

/********************
 * COMPOSITIONS      *
 ********************/
const { rewriteHTMLForSnowplowOptOut, initializeSnowplowOptOut } = useSnowplow();
const { delegateLinksToRouter } = useDelegateToRouter();

/********************
 * REFS & VARS       *
 ********************/
const richtextRef = ref<HTMLElement | null>(null);
const html = ref(rewriteHTMLForSnowplowOptOut(props.fields.html));
const wide = computed(() => {
  return props.extra && props.extra.type && props.extra.type === 'wide';
});

onMounted(() => {
  initializeSnowplowOptOut(richtextRef.value);
});
</script>

<template>
  <div
    :id="props.fields.anchor_id"
    :lang="props.language"
    class="section section--rich-text py-4"
  >
    <div class="container">
      <div
        class="row"
        :class="{ 'justify-center': !wide }"
      >
        <div
          class="col-12"
          :class="{ 'col-lg-9': !wide }"
        >
          <div
            ref="richtextRef"
            :class="`columns-${props.fields.columns || 1}`"
            @click="delegateLinksToRouter"
            v-html="html"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
